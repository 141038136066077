<template>
    <li class="nav-item dropdown ">
        <a class="nav-link" ref="dropdownToggle" data-toggle="dropdown" href="#">
            <i class="fa fa-phone" 
            :class="{
            'bg-success': isLlamadaEstablecida, 
            'bg-white': !isLlamadaActiva,
            'blinking-bg': isLlamadaEntrante
            }" style="color: white; padding: 5px; border-radius: 50%; display: inline-block;"></i>
            <span v-if="payload" class="badge badge-danger"></span>
        </a>
        <div  ref="dropdownMenu" class="dropdown-menu dropdown-menu-right" style="height: auto; overflow-x: hidden; min-width: 350px;">
            <!-- Mostrar el infobox de la llamada entrante -->
            <WebphoneLlamadaEntrante 
                v-if="payload && (payload.data.tipo_notificacion === 'llamada_entrante' || payload.data.tipo_notificacion === 'llamada_establecida' )"
                :payload="payload"
            />
        </div>
    </li>
</template>
<script>

import $ from 'jquery'
import WebphoneLlamadaEntrante from './WebphoneLlamadaEntrante.vue';


export default({
    props: ['payload'],
    components: {
        WebphoneLlamadaEntrante
    },
    data() {
        return {
        }
    },
    computed: {
        // Si la llamada es entrante, parpadea
        isLlamadaEntrante() {
            return this.payload && this.payload.data.tipo_notificacion === 'llamada_entrante';
        },
        // Si la llamada está establecida, el fondo es verde
        isLlamadaEstablecida() {
            return this.payload && this.payload.data.tipo_notificacion === 'llamada_establecida';
        },
        // Si hay una llamada activa (entrante o establecida)
        isLlamadaActiva() {
            return this.payload && (this.payload.data.tipo_notificacion === 'llamada_entrante' || this.payload.data.tipo_notificacion === 'llamada_establecida');
        }
    },
    watch: {
        payload(newPayload) {
        if (newPayload) {
            this.manejarNotificacion(newPayload);
        }
        }
    },
    methods: {
        manejarNotificacion(newPayload) {
            const dropdownToggle = this.$refs.dropdownToggle;
            const dropdownMenu = this.$refs.dropdownMenu;

            if (newPayload.data.tipo_notificacion === 'llamada_entrante' || newPayload.data.tipo_notificacion === 'llamada_establecida') {
                // Mostrar el dropdown
                $(dropdownToggle).dropdown('show');

                // Evitar que se cierre automáticamente
                $(dropdownMenu).on('hide.bs.dropdown', function (e) {
                e.preventDefault();
                });

            } else if (newPayload.data.tipo_notificacion === 'llamada_finalizada') {
                // Cerrar el dropdown manualmente
                $(dropdownToggle).dropdown('hide');

                // Permitir que se cierre el dropdown después de llamada finalizada
                $(dropdownMenu).off('hide.bs.dropdown'); // Remover la prevención
            }
        }
    },
    mounted() {
    }
})
</script>
<style scoped>
    @keyframes blinkingBackground {
    0% { background-color: white; }
    50% { background-color: green; }
    100% { background-color: white; }
    }

    .blinking-bg {
    animation: blinkingBackground 1s infinite;
    }
</style>